<script setup>
import { useHomeStore } from '~/stores/home.store';

const homeStore = useHomeStore();
const countries = ref([]);
countries.value = await homeStore.fetchProjects();
</script>

<template>
  <div class="px-4 my-4">
    <div class="mb-4" v-for="country in countries">
      <div class="flex items-center justify-between mb-2">
        <h3 class="text-lg font-medium">{{ $t('client.top_ten_projects_in_location', { location: country.name }) }}</h3>
        <div class="flex items-center gap-1 text-indigo-900">
          <NuxtLink :to="localePath({ name: 'projects.index', params: { location: country.slug } })">{{
            $t('client.more') }}</NuxtLink>
          <i class="fa-solid fa-chevron-right"></i>
        </div>
      </div>
      <div class="w-full flex gap-2 snap-x overflow-x-auto">
        <div class="snap-start scroll-ml-3 shrink-0 relative" v-for="project in country.projects" :id="project._id">
          <NuxtLink :to="localePath({ name: 'projects.index', params: { location: project.slug } })"
            class="block relative">
            <img :src="project.picture" :alt="project.name" class="w-36 h-28 rounded-xl overflow-hidden object-fill">
            <div
              class=" absolute bottom-0 flex justify-center bg-gradient-to-t from-black to-black/0 h-10 w-full p-1 overflow-hidden rounded-xl">
              <h3 class="text-white text-base truncate">{{ project.name }}</h3>
            </div>
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>